// extracted by mini-css-extract-plugin
export var connectButton = "partners-and-promocodes-page-template-module--connectButton--44692";
export var container = "partners-and-promocodes-page-template-module--container--00357";
export var content = "partners-and-promocodes-page-template-module--content--56c90";
export var copyButton = "partners-and-promocodes-page-template-module--copyButton--584f7";
export var description = "partners-and-promocodes-page-template-module--description--0c689";
export var logoPlaceholder = "partners-and-promocodes-page-template-module--logoPlaceholder--1b6ec";
export var partnerCard = "partners-and-promocodes-page-template-module--partnerCard--dd10a";
export var partnerDescription = "partners-and-promocodes-page-template-module--partnerDescription--0c214";
export var partnerLink = "partners-and-promocodes-page-template-module--partnerLink--5101c";
export var partnerLogo = "partners-and-promocodes-page-template-module--partnerLogo--eda85";
export var partnerName = "partners-and-promocodes-page-template-module--partnerName--d894d";
export var partnersBlock = "partners-and-promocodes-page-template-module--partnersBlock--4ce73";
export var partnersGrid = "partners-and-promocodes-page-template-module--partnersGrid--de12e";
export var promocodeCard = "partners-and-promocodes-page-template-module--promocodeCard--6a735";
export var promocodeCode = "partners-and-promocodes-page-template-module--promocodeCode--3e09a";
export var promocodeDescription = "partners-and-promocodes-page-template-module--promocodeDescription--a653e";
export var promocodeExpiry = "partners-and-promocodes-page-template-module--promocodeExpiry--3badd";
export var promocodeName = "partners-and-promocodes-page-template-module--promocodeName--954ff";
export var promocodePartner = "partners-and-promocodes-page-template-module--promocodePartner--204a3";
export var promocodeValue = "partners-and-promocodes-page-template-module--promocodeValue--e70ef";
export var promocodesBlock = "partners-and-promocodes-page-template-module--promocodesBlock--bb424";
export var section = "partners-and-promocodes-page-template-module--section--b850c";
export var subtitle = "partners-and-promocodes-page-template-module--subtitle--40b98";
export var title = "partners-and-promocodes-page-template-module--title--9e302";